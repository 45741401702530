import {
  APP_IDLE_TIME,
  CUSTOMER_STATUS,
  CUSTOMER_TYPES,
  RESTRICTED_FOLDERS,
  STATUS,
  USER_ACTIVITY_TYPES,
} from "./constants/common";
import store from "../store/store";
import { resetAuthReducer } from "../store/features/Auth.slice";
import {
  NotificationType,
  resetCommonReducer,
  setToastMessage,
} from "../store/features/Common.slice";
import { resetSignupReducer } from "../store/features/Signup.slice";
import { IUser } from "../interface/Auth";
import { UserActivity } from "../pages/CustomerDetails/CustomerInfo/CustomerInfoStyled";
import { ICustomer } from "../interface/Customer";
import {
  COMMON_MENU,
  PLATFORM_CUSTOMERS,
  PROD_PLATFORM_CUSTOMERS,
  PRODUCT_CUSTOMERS,
  TMenuItem,
} from "./constants/menu";
import { ASSIGNED_USER_ROLE } from "../pages/CustomerDetails/CustomerDetailsData";

export const capitalizeFirstLetter = (string: string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const triggerToastMessage = (
  message: string,
  type: NotificationType,
  description = "",
  category = ""
) => {
  store.dispatch(
    setToastMessage({
      showToast: true,
      toastMessage: message,
      toastType: type,
      toastDescription: description,
      category,
    })
  );
};

export const checkUserInactivity = (date1: string) => {
  let diff: number;
  const lastActive = new Date(date1);
  const today = new Date();
  if (lastActive > today) {
    diff = lastActive.getTime() - today.getTime();
  } else {
    diff = today.getTime() - lastActive.getTime();
  }
  return diff / 1000 > APP_IDLE_TIME * 60;
};

export const logoutUser = () => {
  localStorage.clear();
  resetReducer();
};

export const resetReducer = () => {
  store.dispatch(resetAuthReducer());
  store.dispatch(resetCommonReducer());
  store.dispatch(resetSignupReducer());
};

export const copyContent = (content: string) => {
  window.navigator.clipboard.writeText(content);
  triggerToastMessage("Copied to clipboard", STATUS.SUCCESS);
};

export const hasNoProducts = () => {
  if (localStorage.getItem("user")) {
    const user: IUser = JSON.parse(localStorage.getItem("user") || "");
    const { is_super_admin, is_node_exists } = user;
    return is_super_admin && !is_node_exists;
  }
  return false;
};

export const isSuperAdminUser = () => {
  if (localStorage.getItem("user")) {
    const user: IUser = JSON.parse(localStorage.getItem("user") || "");
    return user?.is_super_admin && user?.is_pragmatic_user;
  }
};

export const isViewerUser = () => {
  if (localStorage.getItem("user")) {
    const user: IUser = JSON.parse(localStorage.getItem("user") || "");
    return !user?.is_super_admin && user?.is_pragmatic_user;
  }
};

export const isIndividualUser = () => {
  if (localStorage.getItem("user")) {
    const user: IUser = JSON.parse(localStorage.getItem("user") || "");
    return !user?.is_pragmatic_user;
  }
};

export interface ICheckAdminPermission {
  checkCustomerAssignedRole: boolean;
  userId?: string;
}

export const hasAdminPrivilege = (
  checkCustomerAssignedRole: boolean = false,
  userId: string = "null"
) => {
  // give checkCustomerAssignedRole as "true" only for actions occurring after navigating to a customer.
  if (localStorage.getItem("user")) {
    const user: IUser = JSON.parse(localStorage.getItem("user") || "");
    const currentCustomer: ICustomer = JSON.parse(
      `${localStorage.getItem("currentCustomer")}`
    );

    const hasAdminRole = checkCustomerAssignedRole
      ? currentCustomer?.role?.toLowerCase() === ASSIGNED_USER_ROLE.ADMIN
      : true;
    const isUserAdmin =
      hasAdminRole && user?.is_admin && user?.is_pragmatic_user;
    return userId !== "null" ? isUserAdmin && userId === user?.id : isUserAdmin;
  }
  return true;
};

export const customerType = () => {
  if (localStorage.getItem("user")) {
    const user: IUser = JSON.parse(localStorage.getItem("user") || "");
    const {
      customer: { assigned_platforms, assigned_products },
    } = user;
    if (assigned_products > 0) return CUSTOMER_TYPES.PROD;
    if (assigned_products === 0 && assigned_platforms === 0)
      return CUSTOMER_TYPES.NO_PROD;
    return CUSTOMER_TYPES.PLATFORM;
  }
};

export const customerMenu = (): TMenuItem[] => {
  if (localStorage.getItem("user")) {
    const user: IUser = JSON.parse(localStorage.getItem("user") || "");
    const {
      customer: { assigned_platforms, assigned_products },
    } = user;
    if (assigned_platforms === 0 && assigned_products > 0) {
      return PRODUCT_CUSTOMERS;
    }
    if (assigned_products === 0 && assigned_platforms > 0) {
      return PLATFORM_CUSTOMERS;
    }
    if (assigned_products > 0 && assigned_platforms > 0) {
      return PROD_PLATFORM_CUSTOMERS;
    }
    return COMMON_MENU;
  }
  return COMMON_MENU;
};

export const isInactiveCustomer = () => {
  if (localStorage.getItem("currentCustomer")) {
    const customer: ICustomer = JSON.parse(
      localStorage.getItem("currentCustomer") || ""
    );
    return customer?.status?.toLowerCase() === CUSTOMER_STATUS.INACTIVE;
  }
};

export type FileType = {
  payload: any;
  fileName: string;
  fileType?: string;
};

export const downloadFile = (fileInfo: FileType) => {
  const { payload, fileName, fileType } = fileInfo;
  const element = document.createElement("a");
  const file = new Blob([payload], {
    type: fileType ?? "text/plain;charset=utf-8",
  });
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
};

export const downloadDoc = (fileUrl: string) => {
  const element = document.createElement("a");
  element.href = fileUrl;
  element.download = fileUrl;
  document.body.appendChild(element);
  element.click();
};

export const byteConverter = (
  bytes: number,
  decimals: number,
  only?: string
) => {
  const K_UNIT = 1000;
  const SIZES = ["Bytes", "KB", "MB", "GB", "TB", "PB"];

  if (bytes === 0) return "0 Byte";

  if (only === "MB")
    return (bytes / (K_UNIT * K_UNIT)).toFixed(decimals) + " MB";

  let i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
  i = i < 0 ? 0 : i;
  let resp =
    parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) +
    " " +
    SIZES[i];

  return resp;
};

export const isRestrictedFolder = (nodeName: string) => {
  return RESTRICTED_FOLDERS.includes(nodeName?.toLowerCase());
};

export const checkTime = (time: string) => {
  const now = new Date().toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return now === time ? "Now" : time;
};

export const formatTimelineData = (
  timelineData: any[],
  type: string = USER_ACTIVITY_TYPES.customer
) => {
  const formattedData = timelineData?.map((item: any) => {
    const { message, user, role, event_time, id } = item;
    let data = {
      id: id,
      label: checkTime(event_time),
      children:
        type === USER_ACTIVITY_TYPES.customer ? (
          <UserActivity
            message={message}
            user={user}
            role={role}
            key={event_time}
          />
        ) : (
          message
        ),
    };
    return data;
  });
  return formattedData;
};

export const isCurrentUser = (userId: string) => {
  if (localStorage.getItem("user")) {
    const currUser = JSON.parse(localStorage.getItem("user") || "") || {};
    return userId === currUser?.id;
  }
  return false;
};

import ActionMenu from "../ActionMenu/ActionMenu";
import { MenuProps, Tooltip } from "antd";
import { useAppDispatch } from "../../hooks/reduxHooks";
import {
  setSelectedActionItem,
  setProductActionModalSt,
} from "../../store/features/Product.slice";
import {
  ICheckAdminPermission,
  hasAdminPrivilege,
  isSuperAdminUser,
} from "../../utils/utils";
import { FODLER_ACTIONS, PRODUCT_TYPES } from "../../utils/constants/common";
import { setOpenRenameFolderModal } from "../../store/features/FolderTree";

const ProductTableAction = ({
  data,
  items,
  allowAdmin,
}: {
  data: any;
  items: MenuProps["items"];
  allowAdmin?: ICheckAdminPermission;
}) => {
  const dispatch = useAppDispatch();
  const isSuperAdmin = isSuperAdminUser();
  const hasAdminPermission = hasAdminPrivilege(
    allowAdmin?.checkCustomerAssignedRole,
    allowAdmin?.userId
  );

  const handleMenuClick = (item: { key: string }) => {
    dispatch(setSelectedActionItem(data));
    switch (item.key) {
      case PRODUCT_TYPES.product:
      case PRODUCT_TYPES.platform:
        dispatch(setProductActionModalSt(true));
        break;
      case FODLER_ACTIONS.RENAME:
        dispatch(setOpenRenameFolderModal(true));
        break;
      default:
        break;
    }
  };

  return (
    <>
      {(isSuperAdmin || hasAdminPermission) && (
        <Tooltip placement="top" title="More Actions">
          <div className="actions">
            <ActionMenu
              data={data}
              items={items}
              handleAction={(item: any) => handleMenuClick(item)}
            />
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default ProductTableAction;

export const RESPONSE_MESSAGES = {
  LOGIN_SUCCESS: "Successfully Logged in",
  ADD_CUSTOMER: {
    message: "Customer added",
    description: "You added a customer",
  },
  ADD_USER: {
    message: "User added",
    description: "We sent an invitation to this user",
  },
  ADD_CUSTOMER_USER: {
    message: "User added",
    description: "We sent an invitation to this user",
  },
  ADD_PRODUCT: {
    message: " added",
    description: "You added a ",
  },
  ADD_FOLDER: {
    message: "Folder added",
    description: "You added a folder ",
  },
  IP_UNBLOCKED: {
    message: "IP address unblocked",
  },
  ASSIGN_USER: {
    message: "User assigned",
    description: "You assigned a user to this customer",
  },
  ASSIGN_PRODUCT: {
    message: "Product assigned",
    description: "You assigned a product",
  },
  ASSIGN_PLATFORM: {
    message: "Platform assigned",
    description: "You assigned a platform",
  },
  EDIT_USER: {
    message: "User info updated",
    name_changed: "Name has been updated",
    status_changed: "Status has been updated",
    role_changed: "Role has been updated",
    both_changed: "Name and status have been updated",
  },
  SUPPORT_TICKET: {
    message: "Thank you for reaching out!",
    sub: "Our team will review it shortly.",
  },
  DELTE_PRODUCT: {
    message: " deleted",
    description: "You deleted the ",
  },
  UNASSIGN_PRODUCT: {
    message: " unassigned",
    description: "You unassigned the ",
  },
  RESET_PASSWORD: {
    message: "Your password has been reset.",
    mail_sent: "Password reset email sent!",
    mail_sent_subtext:
      "If the email address you entered is associated with this account, you'll receive a password reset email shortly.",
  },
};

export const CONFIRM_MODAL_MESSAGES: {
  [key: string]: { title: string; description: string };
} = {
  PRODUCTS_AND_PLATFORMS: {
    title: "Are you sure you want to delete this ",
    description:
      "This will be removed from the list and affect all assigned customers but will be recoverable for 30 days.",
  },
  CUSTOMER_PRODUCTS: {
    title: "Are you sure you want to unassign this ",
    description:
      "This product will no longer be shared with the customer, but you can reassign it at any time.",
  },
  DELETE_USER: {
    title: "Are you sure you want to delete this user?",
    description:
      "Deleting this user will revoke their access to the system. However, the user and their associated data will be recoverable for the next 30 days.",
  },
  DELETE_CUSTOMER: {
    title: "Are you sure you want to delete this customer?",
    description:
      "All data associated with this customer, users, and assigned products will be removed but will be recoverable for 30 days.",
  },
};

import { useEffect } from "react";
import UserInfoSection from "../../../components/UserInfoSection/UserInfoSection";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { Spacer } from "./CustomerInfoStyled";
import { setCustomerDetails } from "../../../store/features/CustomerDetails.slice";
import {
  STATUS,
  STORAGE_KEYS,
  USER_ACTIVITY_TYPES,
} from "../../../utils/constants/common";
import ActivityLog from "../../ActivityLog/ActivityLog";
import {
  reloadList,
  resetCommonReducer,
} from "../../../store/features/CustomerInfo.slice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { triggerToastMessage } from "../../../utils/utils";
import { DELETE } from "../../../service/api";
import { ApiUrl } from "../../../utils/constants/ApiUrl";
import { updateToggleLoader } from "../../../store/features/Common.slice";

const CustomerInfo = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { customerDetails } = useAppSelector(
    (state) => state.CustomerDetailsReducer
  );

  const customer = JSON.parse(
    `${localStorage.getItem(STORAGE_KEYS.currCustomer)}`
  );
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(reloadList(true));
    dispatch(resetCommonReducer());
    !customerDetails.id && dispatch(setCustomerDetails(customer));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const deleteCustomer = () => {
    dispatch(updateToggleLoader(true));
    dispatch(
      DELETE("user/delete", `${ApiUrl.customerList}/${customerDetails.id}/`)()
    ).then((res: any) => {
      if (res?.payload?.status === STATUS.SUCCESS) {
        dispatch(updateToggleLoader(false));
        navigate("/home/customers");
        triggerToastMessage(
          "Customer deleted",
          undefined,
          `You deleted '${customerDetails.name}'`
        );
      }
    });
  };

  return (
    <>
      <UserInfoSection
        type="Customer"
        data={{
          id: customerDetails.id,
          name: customerDetails.name,
          status: customerDetails.status,
        }}
        onDelete={deleteCustomer}
      />
      <Spacer />
      <ActivityLog type={USER_ACTIVITY_TYPES.customer} />
    </>
  );
};

export default CustomerInfo;
